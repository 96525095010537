import { latamCountryCodes } from '@app/services/countries';

export const getFacebookShareUrl = (url: string, campaign = '') =>
  encodeURI(
    `https://www.facebook.com/sharer/sharer.php?u=${url}&utm_campaign=${campaign}&utm_source=facebook`,
  );

export const getTwitterShareUrl = (url: string, text: string = null) => {
  let textParam = '';
  if (text) {
    textParam = `&text=${encodeURI(text)}`;
  }

  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    url,
  )}${textParam}&via=mubi`;
};

export type SocialMediaApps =
  | 'facebook'
  | 'instagram'
  | 'threads'
  | 'tiktok'
  | 'twitter'
  | 'youtube';

export const socialMediaLinks: Record<
  SocialMediaApps,
  {
    domain: string;
    countries: Record<string, string>;
  }
> = {
  facebook: {
    domain: 'https://www.facebook.com/',
    countries: {
      br: 'mubibrasil',
      ca: 'mubicanada',
      de: 'mubideutschland',
      fr: 'mubifrance',
      gb: 'mubiuk',
      ie: 'mubiuk',
      in: 'mubiindia',
      it: 'mubiitalia',
      latam: 'mubilat',
      tr: 'mubiturkiye',
      us: 'mubiusa',
      default: 'mubi',
    },
  },
  instagram: {
    domain: 'https://www.instagram.com/',
    countries: {
      br: 'mubibrasil',
      ca: 'mubicanada',
      de: 'mubideutschland',
      fr: 'mubifrance',
      gb: 'mubiuk',
      ie: 'mubiuk',
      in: 'mubiindia',
      it: 'mubiitalia',
      latam: 'mubilat',
      nl: 'mubinederland',
      tr: 'mubiturkiye',
      us: 'mubiusa',
      default: 'mubi',
    },
  },
  threads: {
    domain: 'https://www.threads.net/',
    countries: {
      br: '@mubibrasil',
      ca: '@mubicanada',
      de: '@mubideutschland',
      fr: '@mubifrance',
      gb: '@mubiuk',
      ie: '@mubiuk',
      in: '@mubiindia',
      latam: '@mubilat',
      tr: '@mubiturkiye',
      us: '@mubiusa',
      default: '@mubi',
    },
  },
  tiktok: {
    domain: 'https://www.tiktok.com/',
    countries: {
      default: '@mubi',
    },
  },
  twitter: {
    domain: 'https://twitter.com/',
    countries: {
      br: 'mubibrasil',
      ca: 'mubicanada',
      de: 'mubideutschland',
      fr: 'mubifrance',
      gb: 'mubiuk',
      ie: 'mubiuk',
      in: 'mubiindia',
      it: 'mubiitalia',
      latam: 'mubilat',
      tr: 'mubiturkiye',
      us: 'mubiusa',
      default: 'mubi',
    },
  },
  youtube: {
    domain: 'https://www.youtube.com/',
    countries: {
      br: '@mubilat',
      latam: '@mubilat',
      tr: '@mubiturkiye',
      default: 'channel/UCb6-VM5UQ4Czj_d3m9EPGfg',
    },
  },
};

export const getSocialMediaLink = (
  appName: SocialMediaApps,
  country: string,
): string => {
  const app = socialMediaLinks[appName];
  const countryPath = app.countries[country];
  const defaultPath = app.countries.default;

  if (latamCountryCodes.includes(country) && app.countries.latam) {
    return `${app.domain}${app.countries.latam}`;
  }

  return `${app.domain}${countryPath || defaultPath}`;
};
