import { css, SerializedStyles } from '@emotion/react';

import { Promo } from '@app/api/resources/Promo';

type PromoCtaContainerProps = {
  promo: Promo;
  children: (styles: SerializedStyles) => JSX.Element;
};

const PromoCtaContainer = ({ promo, children }: PromoCtaContainerProps) => {
  const {
    special_promo_splash_page: {
      primary_color: bgColor,
      button_hover_background_color: hoverBgColor,
      button_font_color: fontColor,
    },
  } = promo;

  const promoCtaStyles = css`
    ${fontColor && `color: #${fontColor};`};
    ${bgColor && `background-color: #${bgColor};`};
    ${bgColor && `border-color: #${bgColor};`};
    :hover:not(:disabled) {
      ${hoverBgColor && `background-color: #${hoverBgColor};`};
      ${hoverBgColor && `border-color: #${hoverBgColor};`};
    }
  `;

  return children(promoCtaStyles);
};

export default PromoCtaContainer;
