import { color as themeColors } from '@app/themes/mubi-theme';

import Svg from '@app/components/icons/Svg';

type WidthColorProps = {
  width?: string;
  color?: string;
};

export const TwitterIcon = ({
  color = themeColors.social.twitter,
  width = '24px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 24 19" fill={color} width={width}>
    <path d="M21.0034779,5.34998411 C21.0034779,5.14234375 21.0034779,4.93563869 20.9894482,4.72986896 C21.9283487,4.05074546 22.7388234,3.20986608 23.3829243,2.2466024 C22.5073545,2.63457354 21.5785382,2.88900489 20.6274805,3.0014032 C21.6289655,2.40184824 22.3785094,1.45885224 22.7366203,0.347908948 C21.7949034,0.906719044 20.7646411,1.30054053 19.6902929,1.51237858 C18.2032163,-0.0688736457 15.8402657,-0.455890687 13.9264481,0.568344384 C12.0126305,1.59257946 11.023903,3.77334436 11.5146872,5.88779137 C7.65734253,5.69441415 4.06346193,3.87248566 1.62745153,0.87542772 C0.35413229,3.06747841 1.0045191,5.87175955 3.11273488,7.27954303 C2.34927417,7.25691542 1.60245927,7.05096388 0.935316972,6.67906953 L0.935316972,6.73986513 C0.935941888,9.02352848 2.54570036,10.990446 4.78414631,11.4426389 C4.07786003,11.6352581 3.33681867,11.6634151 2.6179522,11.5249468 C3.24643651,13.4792194 5.04750778,14.8179957 7.09999113,14.8565458 C5.40120769,16.1916471 3.30265989,16.9164203 1.14202202,16.9142432 C0.760322504,16.9135104 0.378994239,16.8903996 -1.42108547e-14,16.8450297 C2.19391311,18.2529404 4.74665263,18.9997352 7.35346203,18.9962587 C16.1772423,19 21.0034779,11.6895625 21.0034779,5.34998411 Z" />
  </Svg>
);

export const FacebookIcon = ({
  color = themeColors.social.facebook,
  width = '20px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 20 20" fill={color} width={width}>
    <path d="M1.06553888,0 C0.475605335,0 0,0.404682396 0,1 L0,19 C0,19.5953176 0.475605335,20 1.06553888,20 L9.99910494,20 L9.99910494,12 L7.5,12 L7.5,9 L9.99910494,9 L9.99910494,6.89989111 C9.99910494,4.50108893 11.490143,3.01012704 13.8125299,3.01012704 C15.211145,3.01012704 16.151685,3.12065336 16.151685,3.12065336 L16.151685,5.82511797 L14.5770045,5.82511797 C13.5785382,5.82511797 12.9988593,6.17956443 12.9988593,7.24671506 L12.9988593,9 L16,9 L15.5500002,12 L12.9988593,12 L12.9988593,20 L19,20 C19.5899335,20 20,19.5953176 20,19 L20,1 C20,0.404682396 19.5899335,0 19,0 L1.06553888,0 Z" />
  </Svg>
);

export const TumblrIcon = ({ color = '#314358', width }: WidthColorProps) => (
  <Svg viewBox="0 0 256 256" fill={color} width={width}>
    <path d="M.24.167h255.52v256H.24 M168.08 170.918c-2.969 1.416-8.647 2.648-12.881 2.754-12.783.342-15.264-8.979-15.367-15.736v-49.705h32.065V84.055h-31.954V43.382H116.56c-.385 0-1.057.337-1.152 1.192-1.368 12.448-7.192 34.296-31.416 43.032v20.624h16.16v52.167c0 17.863 13.176 43.24 47.959 42.641 11.736-.201 24.77-5.113 27.648-9.354l-7.679-22.766z" />
  </Svg>
);

export const CopyLinkIcon = ({
  color = themeColors.white,
  width = '29px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 29 29" width={width}>
    <g fill={color} fillRule="nonzero">
      <path d="M26.2 12.25a7.36 7.36 0 000-10.09 6.67 6.67 0 00-9.7 0L10.62 8.3a7.36 7.36 0 00.79 10.79l1.88-1.96a4.55 4.55 0 01-.82-6.9l5.88-6.14a4.12 4.12 0 016 0 4.55 4.55 0 010 6.25l-1.37 1.43c.36 1.03.56 2.12.6 3.22l2.61-2.73z" />
      <path d="M2.9 16.18a7.36 7.36 0 000 10.09 6.67 6.67 0 009.7 0l5.88-6.13a7.36 7.36 0 00-.79-10.79l-1.88 1.96a4.55 4.55 0 01.82 6.9l-5.88 6.13a4.12 4.12 0 01-6 0 4.55 4.55 0 010-6.24l1.37-1.43a10.9 10.9 0 01-.6-3.22l-2.61 2.73z" />
    </g>
  </Svg>
);

export const WhatsAppIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 24 24">
    <path
      fill={color}
      d="m.057 24 1.687-6.163a11.867 11.867 0 0 1-1.587-5.946C.16 5.335 5.495 0 12.05 0a11.817 11.817 0 0 1 8.413 3.488 11.824 11.824 0 0 1 3.48 8.414c-.003 6.557-5.338 11.892-11.893 11.892a11.9 11.9 0 0 1-5.688-1.448L.057 24zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
    />
  </Svg>
);

export const MessengerIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 0C5.373 0 0 4.975 0 11.111c0 3.497 1.745 6.616 4.472 8.652V24l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111C24 4.975 18.627 0 12 0zm1.193 14.963-3.056-3.259-5.963 3.259L10.733 8l3.13 3.259L19.752 8l-6.559 6.963z"
    />
  </Svg>
);

export const InstagramIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z"
    />
  </Svg>
);

// 🤢
export const XIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 256 256">
    <path
      fill={color}
      d="M.2 2.9 35 49.3 0 87.1h7.9l30.6-33 24.7 33H90l-36.7-49L85.8 2.8H78L49.8 33.3 27 3H.2zm11.6 5.8H24l54.3 72.6H66.1L11.8 8.7z"
      transform="matrix(2.81 0 0 2.81 1.4 1.4)"
    />
  </Svg>
);

export const FacebookRoundIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 2a10 10 0 0 0-1.6 19.9v-7H8V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9l2.3.2v2.5h-1.3c-1.2 0-1.6.7-1.6 1.5V12h2.7l-.4 2.9h-2.3v7A10 10 0 0 0 12 2z"
    />
  </Svg>
);

export const YouTubeIcon = ({
  width = '21px',
  color = themeColors.black,
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 21 15">
    <defs>
      <path id="a" d="M0 0h21v15H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <path
        d="M8.352 10.666V4.334L13.841 7.5l-5.489 3.166Zm12.21-8.324A2.657 2.657 0 0 0 18.704.448C17.067 0 10.5 0 10.5 0S3.933 0 2.295.448A2.657 2.657 0 0 0 .44 2.342C0 4.013 0 7.5 0 7.5s0 3.487.439 5.158a2.658 2.658 0 0 0 1.856 1.894C3.933 15 10.5 15 10.5 15s6.567 0 8.205-.448a2.658 2.658 0 0 0 1.856-1.894C21 10.987 21 7.5 21 7.5s0-3.487-.439-5.158Z"
        fill={color}
        mask="url(#b)"
      />
    </g>
  </Svg>
);

export const ThreadsLogoIcon = ({
  width = '16px',
  color = themeColors.black,
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 16 18">
    <path
      d="M8.034 18H8.03c-2.685-.018-4.75-.904-6.137-2.632C.659 13.83.022 11.69 0 9.008v-.013C.022 6.31.659 4.172 1.893 2.633 3.28.903 5.345.018 8.03 0h.011c2.059.014 3.781.544 5.118 1.573 1.258.969 2.144 2.347 2.631 4.1l-1.53.427c-.827-2.97-2.922-4.487-6.227-4.51-2.18.016-3.831.701-4.904 2.037C2.126 4.878 1.608 6.685 1.588 9c.02 2.315.538 4.122 1.542 5.373 1.072 1.337 2.723 2.023 4.904 2.038 1.967-.015 3.269-.474 4.35-1.534 1.235-1.21 1.213-2.695.817-3.598-.232-.533-.655-.976-1.225-1.312-.144 1.013-.466 1.834-.963 2.453-.664.826-1.606 1.278-2.797 1.343-.902.048-1.771-.164-2.444-.601-.797-.517-1.264-1.305-1.314-2.223-.049-.893.306-1.714.997-2.312.66-.57 1.59-.905 2.687-.968a10.387 10.387 0 0 1 2.264.106c-.093-.556-.28-.999-.561-1.317-.385-.44-.981-.663-1.77-.668h-.021c-.634 0-1.494.174-2.04.99l-1.318-.884c.734-1.09 1.925-1.692 3.358-1.692h.032c2.396.014 3.823 1.481 3.965 4.041.081.034.162.07.242.106 1.117.526 1.934 1.321 2.364 2.302.598 1.367.654 3.593-1.16 5.37-1.388 1.357-3.07 1.97-5.457 1.987h-.006Zm.753-8.768c-.182 0-.366.006-.555.016-1.377.078-2.235.71-2.187 1.608.05.941 1.09 1.379 2.088 1.325.918-.049 2.113-.407 2.315-2.783a7.873 7.873 0 0 0-1.661-.166Z"
      fill={color}
      fillRule="nonzero"
    />
  </Svg>
);

export const TikTokLogoIcon = ({
  width = '16px',
  color = themeColors.black,
}: WidthColorProps) => (
  <Svg width={width} viewBox="0 0 512 512">
    <path
      fill={color}
      d="M412.2 118.7a109.3 109.3 0 0 1-9.5-5.5 132.9 132.9 0 0 1-24.2-20.7A114.5 114.5 0 0 1 351 36.1h.1a69.5 69.5 0 0 1-1-20.1h-82.5v318.8c0 4.3 0 8.5-.2 12.7v1.5l-.1.7v.2a70 70 0 0 1-35.2 55.6 68.8 68.8 0 0 1-34.1 9c-38.5 0-69.6-31.3-69.6-70s31.1-70 69.5-70a68.9 68.9 0 0 1 21.5 3.4v-84a153.1 153.1 0 0 0-118 34.5 161.8 161.8 0 0 0-35 43.6c-3.5 6-16.6 30-18.2 69.2a163 163 0 0 0 8.8 54.7v.3A164 164 0 0 0 79.4 437a167.5 167.5 0 0 0 35.7 33.7v-.2l.2.2c40 27 84.2 25.3 84.2 25.3 7.6-.3 33.3 0 62.4-13.8 32.3-15.3 50.7-38.1 50.7-38.1a158.5 158.5 0 0 0 27.7-46 172 172 0 0 0 10-52.5V176.5l14.2 9.4s19.2 12.3 49.2 20.3a283 283 0 0 0 50.4 7v-82c-10.1 1.2-30.7-2-51.8-12.5Z"
    />
  </Svg>
);
