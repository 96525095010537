import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import FilmTileWithImpressionTracking from '@app/components/film-tile/default/FilmTileWithImpressionTracking';

type PromoFeaturedFilmProps = {
  film: Film;
  promoCampaign: string;
};

const PromoFeaturedFilm = ({ film, promoCampaign }: PromoFeaturedFilmProps) => {
  const activeSubscriber = useAppSelector(state => state.user.activeSubscriber);
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const trackEvent = (clickType: string) =>
    trackSnowplowClickEvent({
      data: {
        clickType,
        element: 'featured_film_tile',
      },
      extraData: {
        promo_campaign: promoCampaign,
      },
      film,
    });

  return (
    <FilmTileContainer>
      <FilmTileWithImpressionTracking
        film={film}
        onTileClick={() => trackEvent('film_tile')}
        onPlayButtonClick={() => activeSubscriber && trackEvent('film_play')}
      />
    </FilmTileContainer>
  );
};

const FilmTileContainer = styled.div`
  width: 100%;
  height: ${props => props.theme.grid.standardFilmTileHeight}px;
  margin-bottom: ${props => props.theme.grid.mobileGridPadding}px;

  @media (min-width: ${props => props.theme.mqNew.mobile}) {
    width: ${props => props.theme.grid.standardItemWidth}px;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-bottom: ${props => props.theme.grid.gridMarginBottom}px;
    margin-right: ${props => props.theme.grid.standardGridGap}px;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    &:nth-of-type(2n) {
      margin-right: ${props => props.theme.grid.standardGridGap}px;
    }
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    &:nth-of-type(3n + 3) {
      margin-right: ${props => props.theme.grid.standardGridGap}px;
    }
    &:nth-of-type(4n + 4) {
      margin-right: 0;
    }
  }
`;

export default PromoFeaturedFilm;
