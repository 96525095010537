import { CSSTransition } from 'react-transition-group';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Promo } from '@app/api/resources/Promo';

import { getTwitterShareCopy } from '@app/services/promo-utils';
import {
  getFacebookShareUrl,
  getTwitterShareUrl,
} from '@app/services/social-media';

import PageSection from '@app/components/layout/PageSection';
import SocialButtons from '@app/components/SocialButtons';

type PromoSocialButtons = {
  visible: boolean;
  url: string;
  promo?: Promo;
};

const PromoSocialButtons = ({
  visible,
  url,
  promo = null,
}: PromoSocialButtons) => {
  const { t } = useTranslation('promo');

  let light_background = null;
  let twitterShareCopy = '';
  if (promo) {
    light_background = promo?.special_promo_splash_page?.light_background;
    twitterShareCopy = getTwitterShareCopy(promo, t);
  }
  const iconColor = light_background
    ? 'rgba(50,50,50,0.5)'
    : 'rgba(255,255,255,0.5)';
  return (
    <CSSTransition
      in={visible}
      appear={visible}
      classNames="appear"
      timeout={600}
    >
      <Container>
        <PageSection>
          <Content>
            <ContentInner>
              <SocialButtons
                iconColor={iconColor}
                iconWidth="24px"
                spaceBetweenIconsTablet="20px"
                spaceBetweenIconsDesktop="20px"
                spaceBetweenIconsWide="20px"
                facebookUrl={getFacebookShareUrl(url)}
                twitterUrl={getTwitterShareUrl(url, twitterShareCopy)}
              />
            </ContentInner>
          </Content>
        </PageSection>
      </Container>
    </CSSTransition>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  display: none;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    transition: opacity 0.6s;
    opacity: 0;

    &.appear-enter,
    &.appear-enter-done,
    &.appear-exit {
      display: block;
    }
    &.appear-enter-active,
    &.appear-enter-done {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  position: relative;
`;

const ContentInner = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default PromoSocialButtons;
