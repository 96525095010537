import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import _uniqueId from 'lodash/uniqueId';
import styled from '@emotion/styled';
import type { Placement } from '@popperjs/core';

import { color as themeColors } from '@app/themes/mubi-theme';

import { CopyLinkIcon } from '@app/components/icons/SocialMediaIcons';
import TooltipPosition from '@app/components/tool-tip/TooltipPosition';

type SocialButtonsProps = {
  shareLinkUrl?: string;
  iconColor?: string;
  iconColorHoverOverride?: string;
  iconWidth?: string;
  trackEvent?: (eventName: string) => void;
  space?: string;
  placement?: Placement;
};

const CopyLinkButton = ({
  shareLinkUrl = null,
  iconColor = themeColors.darkGray,
  iconColorHoverOverride = null,
  iconWidth = '20px',
  trackEvent = () => {},
  space = null,
  placement = 'bottom',
}: SocialButtonsProps) => {
  const TWO_SECONDS = 2000;
  const { t } = useTranslation('common');

  const [copyLinkMessage, setCopyLinkMessage] = useState('');
  const [copiedLinkMessage, setCopiedLinkMessage] = useState('');
  // for Popper, stop pages with multiple instances of this component conflicting
  const [uniqueIdPrefix, setUniqueIdPrefix] = useState(null);

  useEffect(() => {
    setUniqueIdPrefix(_uniqueId());
  }, []);

  const copyToClipboard = async () => {
    if (copiedLinkMessage === '') {
      await navigator.clipboard.writeText(shareLinkUrl);
      setCopiedLinkMessage(t('common:common.buttons.copied_link'));
      setTimeout(() => {
        setCopiedLinkMessage('');
      }, TWO_SECONDS);
    }
  };

  if (!uniqueIdPrefix) {
    return (
      <SocialLink
        iconColor={iconColor}
        iconColorHoverOverride={iconColorHoverOverride}
        iconWidth={iconWidth}
        space={space}
      >
        <CopyLinkIcon width="100%" color={null} />
      </SocialLink>
    );
  }

  return (
    <SocialLink
      onClick={e => {
        e.preventDefault();
        trackEvent('recommend_link');
        copyToClipboard();
      }}
      iconColor={iconColor}
      iconColorHoverOverride={iconColorHoverOverride}
      iconWidth={iconWidth}
      space={space}
      onMouseEnter={() =>
        setCopyLinkMessage(t('common:common.buttons.copy_link'))
      }
      onMouseLeave={() => setCopyLinkMessage('')}
    >
      <TooltipPosition
        message={copiedLinkMessage || copyLinkMessage}
        uniqId={`${uniqueIdPrefix}CopyClipboard`}
        placement={placement}
      >
        <CopyLinkIcon width="100%" color={null} />
      </TooltipPosition>
    </SocialLink>
  );
};

type SocialLinkProps = {
  iconWidth: string;
  iconColor: string;
  space: string;
  iconColorHoverOverride?: string;
};

const SocialLink = styled.div<SocialLinkProps>`
  cursor: pointer;
  width: ${props => props.iconWidth};
  height: ${props => props.iconWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.space};

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: ${props => props.iconColor};
  }

  &:hover {
    svg {
      fill: ${props => props.iconColorHoverOverride};
    }
  }
`;

export default CopyLinkButton;
