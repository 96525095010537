import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import PageSection from '@app/components/layout/PageSection';
import PromoFeaturedFilm from '@app/components/promo/PromoFeaturedFilm';

type PromoFeaturedFilmsSectionProps = {
  films: Film[];
  primaryColor: string;
  promoCampaign: string;
};

const PromoFeaturedFilmsSection = ({
  films,
  primaryColor,
  promoCampaign,
}: PromoFeaturedFilmsSectionProps) => {
  const { t } = useTranslation('promo');
  return (
    <Container primaryColor={primaryColor}>
      <PageSection>
        <ContentWrapper>
          <Content>
            <Title>{t('promo:promo.featured_films.title')}</Title>
            <FilmTiles>
              {films.map(film => (
                <PromoFeaturedFilm
                  key={film.slug}
                  film={film}
                  promoCampaign={promoCampaign}
                />
              ))}
            </FilmTiles>
          </Content>
        </ContentWrapper>
      </PageSection>
    </Container>
  );
};

type ContainerProps = {
  primaryColor: string;
};

const Container = styled.div<ContainerProps>`
  color: ${props => props.primaryColor};
`;

// extra wrapper required to fix ie11 flex bug
// https://github.com/philipwalton/flexbugs/blob/master/README.md#flexbug-3
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px;
`;

const FilmTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default PromoFeaturedFilmsSection;
