import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import { getPromo, Promo, validatePromo } from '@app/api/resources/Promo';

import {
  getPromoBody,
  getPromoTitle,
  PROMO_DEFAULT_BG_IMAGE,
} from '@app/services/promo-utils';
import { getFullRouteUrl } from '@app/services/routeHelpers';

import { Context } from '@app/types/common';
import { color as themeColors } from '@app/themes/mubi-theme';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import PromoBackgroundImage from '@app/components/promo/PromoBackgroundImage';
import PromoBenefitsSection from '@app/components/promo/PromoBenefitsSection';
import PromoCta from '@app/components/promo/PromoCta';
import PromoCtaContainer from '@app/components/promo/PromoCtaContainer';
import PromoDisabled from '@app/components/promo/PromoDisabled';
import PromoFeaturedFilmsSection from '@app/components/promo/PromoFeaturedFilmsSection';
import PromoHead from '@app/components/promo/PromoHead';
import PromoHeader from '@app/components/promo/PromoHeader';
import PromoNowShowingSection from '@app/components/promo/PromoNowShowingSection';
import PromoScrollDownArrow from '@app/components/promo/PromoScrollDownArrow';
import PromoSocialButtons from '@app/components/promo/PromoSocialButtons';
import SocialMetaTags from '@app/components/SocialMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';

const PromoUnavailableInLocation = dynamic(
  () =>
    import(
      /* webpackChunkName: "PromoUnavailableInLocation" */
      '@app/components/promo/PromoUnavailableInLocation'
    ),
);

const PromoVanityPathPage = ({
  promo,
  availableInCountry = true,
  apiSignalsUserEligibleForPromo = true,
}: {
  promo: Promo;
  availableInCountry?: boolean;
  apiSignalsUserEligibleForPromo?: boolean;
}) => {
  const { t } = useTranslation('promo');
  const [headIsVisible, setHeadIsVisible] = useState(true);
  const [bottomIsVisible, setBottomIsVisible] = useState(false);
  const i18nUrlData = useI18nUrlData();

  const { disabled, special_promo_splash_page } = promo || {};

  const {
    background_image,
    background_average_colour_hex,
    vanity_path,
    light_background,
    films,
    logo,
  } = special_promo_splash_page || {};

  if (!availableInCountry) {
    return <PromoUnavailableInLocation />;
  }

  if (!promo) {
    return null;
  }

  if (disabled) {
    return <PromoDisabled promo={promo} />;
  }

  const primaryColor = light_background
    ? themeColors.darkText
    : themeColors.white;

  const url = getFullRouteUrl({
    url: `/promos/${vanity_path}`,
    i18nUrlData,
  });

  const showSmallArrowAndCopy = !bottomIsVisible && headIsVisible;
  const showLargeArrow = !bottomIsVisible && !headIsVisible;
  const title = getPromoTitle(promo, t, true);

  const bgImage = background_image || PROMO_DEFAULT_BG_IMAGE;

  return (
    <>
      <StandardHeadTags title={title as string} noIndex />
      <SocialMetaTags
        title={title as string}
        url={url}
        image={`${bgImage}?size=1280x`}
        description={getPromoBody(promo, t, true) as string}
      />
      <StickyContainer>
        <PromoBackgroundImage
          backgroundImg={bgImage}
          backgroundColor={background_average_colour_hex}
        />
      </StickyContainer>
      <PromoHeader
        specialPromoSplashPage={promo.special_promo_splash_page}
        showSecondaryContent={!headIsVisible}
        primaryColor={primaryColor}
        secondaryContentCopy={t('promo:promo.header.stream')}
      >
        <PromoCtaContainer promo={promo}>
          {promoCtaStyles => (
            <PromoCta
              promo={promo}
              apiSignalsUserEligibleForPromo={apiSignalsUserEligibleForPromo}
              Button={
                getHeaderStyledButton(promoCtaStyles) as typeof MubiButton
              }
            />
          )}
        </PromoCtaContainer>
      </PromoHeader>
      <Content>
        <InView threshold={0.25} onChange={setHeadIsVisible}>
          <PromoHead
            primaryColor={primaryColor}
            bodyText={getPromoBody(promo, t)}
            titleText={getPromoTitle(promo, t)}
            logo={logo}
          >
            <PromoCtaContainer promo={promo}>
              {promoCtaStyles => (
                <PromoCta
                  promo={promo}
                  apiSignalsUserEligibleForPromo={
                    apiSignalsUserEligibleForPromo
                  }
                  allowPromoCodeForm
                  Button={getStyledButton(promoCtaStyles) as typeof MubiButton}
                  allowIsEligibleForPromoMessage
                />
              )}
            </PromoCtaContainer>
          </PromoHead>
        </InView>
        <PromoScrollDownArrow
          showSmallArrowAndCopy={showSmallArrowAndCopy}
          showLargeArrow={showLargeArrow}
          primaryColor={primaryColor}
        />
        {
          // TODO: remove this check once promo ends
          promo.campaign !== 'webosweek' ? (
            <PromoSocialButtons
              visible={!headIsVisible}
              url={url}
              promo={promo}
            />
          ) : null
        }
        {films?.length > 0 && (
          <PromoFeaturedFilmsSection
            films={films}
            primaryColor={primaryColor}
            promoCampaign={promo.campaign}
          />
        )}
        <PromoBenefitsSection primaryColor={primaryColor} />
        <InView threshold={1} onChange={setBottomIsVisible}>
          <PromoNowShowingSection
            promoCampaign={promo?.campaign}
            primaryColor={primaryColor}
          >
            <PromoCtaContainer promo={promo}>
              {promoCtaStyles => (
                <PromoCta
                  promo={promo}
                  apiSignalsUserEligibleForPromo={
                    apiSignalsUserEligibleForPromo
                  }
                  Button={getStyledButton(promoCtaStyles) as typeof MubiButton}
                />
              )}
            </PromoCtaContainer>
          </PromoNowShowingSection>
        </InView>
      </Content>
    </>
  );
};

const isPromoAvailableInCountry = (country: string, vanityPath: string) => {
  const promosToRestrictToGBIE = [
    'mubigo_nye',
    'year_mubigo',
    'get_mubigo',
    'letterboxd_mubigo',
    'thenudge_mubigo',
    'littlewhitelies_mubigo',
  ];

  const promosToRestrictToUS = ['go-for20'];

  if (promosToRestrictToUS.includes(vanityPath) && country !== 'US') {
    return false;
  }

  if (
    promosToRestrictToGBIE.includes(vanityPath) &&
    !['GB', 'IE'].includes(country)
  ) {
    return false;
  }

  return true;
};

PromoVanityPathPage.getInitialProps = async (ctx: Context) =>
  getInitialProps(ctx);

export const getInitialProps = async (ctx: Context) => {
  const { store, query } = ctx;
  const currentState = store.getState();
  const httpContext = currentState?.appState?.httpContext;
  const isAuthenticated = currentState?.user.isAuthenticated;
  const geoLocation = currentState?.user?.geoLocation;
  const vanityPath = (query.promoVanityPath || query.vanityPath) as string;

  if (!isPromoAvailableInCountry(geoLocation, vanityPath)) {
    return {
      availableInCountry: false,
    };
  }

  let apiSignalsUserEligibleForPromo = true;
  let promo = null;
  try {
    const response = await getPromo(httpContext, vanityPath);
    promo = response.data;

    if (isAuthenticated && promo?.code) {
      await validatePromo(httpContext, vanityPath, promo?.code);
    }

    return { promo, apiSignalsUserEligibleForPromo };
  } catch (error) {
    // If the user is not eligible for the promo, we don't want to show the redeem button
    if (error?.status === 422 && error?.data?.code === 161 && promo) {
      return { promo, apiSignalsUserEligibleForPromo: false };
    }

    if (error?.status === 404) {
      return {
        errorStatusCode: 404,
      };
    }

    Sentry.captureException(error, {
      extra: {
        promoCode: query.promoVanityPath,
      },
    });
    throw error;
  }
};

export default PromoVanityPathPage;

const Content = styled.div`
  position: relative;
`;

const StickyContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const getStyledButton = promoCtaStyles => styled(MubiButton)`
  font-size: 21px;
  line-height: 1.1;
  padding: 22px 30px;
  ${promoCtaStyles}
  width: fit-content;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 22px 40px;
  }
`;

const getHeaderStyledButton = promoCtaStyles => styled(MubiButton)`
  font-size: 14px;
  line-height: 1.1;
  padding: 14px 16px;
  ${promoCtaStyles}
  margin-left: 30px;
`;
