import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Promo } from '@app/api/resources/Promo';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';
import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import PromoCodeForm from '@app/components/promo/PromoCodeForm';

type PromoCtaProps = {
  promo: Promo;
  allowPromoCodeForm?: boolean;
  Button?: typeof MubiButton;
  buttonTextOverride?: string;
  apiSignalsUserEligibleForPromo?: boolean;
  allowIsEligibleForPromoMessage?: boolean;
};

const PromoCta = ({
  promo,
  allowPromoCodeForm = false,
  Button = MubiButton,
  buttonTextOverride,
  apiSignalsUserEligibleForPromo = true,
  allowIsEligibleForPromoMessage = false,
}: PromoCtaProps) => {
  const {
    type,
    code,
    special_promo_splash_page: { button },
  } = promo;
  const onboardingTrigger = useOnboardingTrigger();
  const { t } = useTranslation('promo');
  const router = useRouter();
  const i18nUrlData = useI18nUrlData();

  const { activeRecurringSubscriber, userIsMarkedForCancellation } =
    useAppSelector(
      state => ({
        activeRecurringSubscriber: state.user.user?.active_recurring_subscriber,
        userIsMarkedForCancellation:
          state.user.user?.subscription?.status === 'marked_for_cancellation',
      }),
      shallowEqual,
    );

  const isEligible = isUserEligible(
    activeRecurringSubscriber,
    promo,
    userIsMarkedForCancellation,
    apiSignalsUserEligibleForPromo,
  );

  if (isEligible) {
    const showPromoCodeForm = (type === 'Vip' || !code) && allowPromoCodeForm;
    if (showPromoCodeForm) {
      return <PromoCodeForm promo={promo} />;
    }

    let copy = t('promo:promo.cta.start_membership');
    if (buttonTextOverride) {
      copy = buttonTextOverride;
    } else if (button) {
      copy = button;
    } else if (type === 'FreeTrial') {
      copy = t('promo:promo.cta.start_free_trial');
    }

    return (
      <Button
        clickAction={() =>
          onboardingTrigger('promoNew', {
            specialPromo: promo,
            contextData: {
              redirectUrl: '/showing',
            },
          })
        }
      >
        {copy}
      </Button>
    );
  }

  return (
    <Container>
      {allowIsEligibleForPromoMessage && (
        <UserIsNotEligible>
          {t('onboarding:onboarding.ineligible_promo.body')}
        </UserIsNotEligible>
      )}
      <Button
        clickAction={() =>
          router.push(
            getFullRouteUrl({
              url: '/showing',
              i18nUrlData,
            }),
          )
        }
      >
        {t('promo:promo.cta.see_showing')}
      </Button>
    </Container>
  );
};

export default PromoCta;

export const isUserEligible = (
  activeRecurringSubscriber: boolean,
  promo: Promo,
  userIsMarkedForCancellation: boolean,
  apiSignalsUserEligibleForPromo: boolean = false,
) => {
  let isEligible = apiSignalsUserEligibleForPromo;

  if (activeRecurringSubscriber) {
    // This is a temporary hack to allow users from Voluntary leavers flow to redeem the promo.
    // This hack will be removed later, so this method will no longer be needed.
    // The promo is a reactivate after cancellation promo
    if (
      userIsMarkedForCancellation &&
      promo.campaign === 'global_discount_40_off_12_months_reactivate'
    ) {
      isEligible = true;
    }
  }

  return isEligible;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
`;

const UserIsNotEligible = styled.div`
  font-size: 16px;
  margin: 120px 0 12px;
`;
