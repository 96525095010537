import styled from '@emotion/styled';

import { openLinkInNewWindow } from '@app/services/utils';

import { color as themeColors } from '@app/themes/mubi-theme';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import ValueAtBreakpoint from '@app/components/breakpoints/ValueAtBreakpoint';
import CopyLinkButton from '@app/components/CopyLinkButton';
import {
  FacebookIcon,
  TwitterIcon,
} from '@app/components/icons/SocialMediaIcons';

type SocialButtonsProps = {
  facebookUrl: string;
  twitterUrl: string;
  shareLinkUrl?: string;
  iconColor?: string;
  iconColorHoverOverride?: string;
  iconWidth?: string;
  spaceBetweenIconsMobile?: string;
  spaceBetweenIconsTablet?: string;
  spaceBetweenIconsDesktop?: string;
  spaceBetweenIconsWide?: string;
  trackEvent?: (eventName: string) => void;
};

const SocialButtons = ({
  facebookUrl,
  twitterUrl,
  shareLinkUrl = null,
  iconColor = themeColors.darkGray,
  iconColorHoverOverride = null,
  iconWidth = '20px',
  spaceBetweenIconsMobile = '10px',
  spaceBetweenIconsTablet = '10px',
  spaceBetweenIconsDesktop = '10px',
  spaceBetweenIconsWide,
  trackEvent = () => {},
}: SocialButtonsProps) => {
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  return (
    <Container>
      <ValueAtBreakpoint
        defaultValue={spaceBetweenIconsMobile}
        valueAtMobile={spaceBetweenIconsMobile}
        valueAtTablet={spaceBetweenIconsTablet}
        valueAtDesktop={spaceBetweenIconsDesktop}
        valueAtWide={spaceBetweenIconsWide}
      >
        {({ currentValue }) => (
          <>
            <SocialLink
              href={facebookUrl}
              onClick={e => {
                e.preventDefault();
                trackEvent('recommend_fb');
                trackSnowplowClickEvent({
                  data: { clickType: 'social_media_facebook' },
                });
                openLinkInNewWindow(facebookUrl);
              }}
              type="facebook"
              iconColor={iconColor}
              iconColorHoverOverride={iconColorHoverOverride}
              iconWidth={iconWidth}
              space={currentValue as string}
            >
              <FacebookIcon width="100%" color={null} />
            </SocialLink>
            {twitterUrl && (
              <SocialLink
                href={twitterUrl}
                onClick={e => {
                  e.preventDefault();
                  trackEvent('recommend_twitter');
                  trackSnowplowClickEvent({
                    data: {
                      clickType: 'social_media_twitter',
                    },
                  });
                  openLinkInNewWindow(twitterUrl);
                }}
                type="twitter"
                iconColor={iconColor}
                iconColorHoverOverride={iconColorHoverOverride}
                iconWidth={iconWidth}
                space={currentValue as string}
              >
                <TwitterIcon width="100%" color={null} />
              </SocialLink>
            )}

            {shareLinkUrl && (
              <CopyLinkButton
                shareLinkUrl={shareLinkUrl}
                iconColor={iconColor}
                iconColorHoverOverride={iconColorHoverOverride}
                iconWidth={iconWidth}
                trackEvent={trackEvent}
                space={currentValue as string}
              />
            )}
          </>
        )}
      </ValueAtBreakpoint>
    </Container>
  );
};

export default SocialButtons;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
`;

type SocialLinkProps = {
  iconWidth: string;
  iconColor: string;
  space: string;
  iconColorHoverOverride?: string;
};

const SocialLink = styled.a<SocialLinkProps>`
  cursor: pointer;
  width: ${props => props.iconWidth};
  height: ${props => props.iconWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.space};

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: ${props => props.iconColor};
  }

  &:hover {
    svg {
      fill: ${props =>
        props.iconColorHoverOverride || props.theme.color.social[props.type]};
    }
  }
`;
