import { FormEvent, useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import type { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { Promo } from '@app/api/resources/Promo';

import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import PromoCtaContainer from '@app/components/promo/PromoCtaContainer';

type PromoCodeForm = {
  promo: Promo;
};

const PromoCodeForm = ({ promo }: PromoCodeForm) => {
  const { t } = useTranslation('promo');
  const router = useRouter();
  const { code } = router.query;
  const [customCode, setCustomCode] = useState(code || '');
  const onboardingTrigger = useOnboardingTrigger();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onboardingTrigger('promoNew', {
      uniqueUserPromoCode: customCode as string,
      specialPromo: promo,
      contextData: {
        redirectUrl: '/showing',
      },
    });
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Input
        name="code"
        type="text"
        placeholder={t('promo:promo.cta.promo_code_placeholder')}
        value={customCode}
        onChange={e => setCustomCode(e.target.value)}
        required
        autoComplete="off"
      />
      <PromoCtaContainer promo={promo}>
        {promoCtaStyles => (
          <SignupButton type="submit" promoCtaStyles={promoCtaStyles}>
            {t('promo:promo.cta.promo_code_submit')}
          </SignupButton>
        )}
      </PromoCtaContainer>
    </Form>
  );
};

type SignupButtonProps = {
  promoCtaStyles: SerializedStyles;
};

const SignupButton = styled(MubiButton)<SignupButtonProps>`
  font-size: 21px;
  line-height: 23px;
  padding: 22px 30px;
  ${props => props.promoCtaStyles}

  @media (max-width: ${props => props.theme.mqNew.tablet}) {
    width: 100%;
    min-height: 61px;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 22px 40px;
  }
`;

const Form = styled.form`
  @media (max-width: ${props => props.theme.mqNew.tablet}) {
    width: 100%;
    max-width: 340px;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
  }
`;

const Input = styled.input`
  font-family: ${props => props.theme.font.body};
  font-size: 18px;
  border: 1px solid ${props => props.theme.color.lightGray};
  outline: none;
  padding: 12px 15px;

  @media (max-width: ${props => `${props.theme.mqNumbers.tablet - 1}px`}) {
    width: 100%;
    height: 61px;
    margin-bottom: 20px;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-right: 10px;
  }
`;

export default PromoCodeForm;
